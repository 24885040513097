<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
                <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    <span v-if="isNew">Nuevo</span>
                    <span v-if="!isNew">Editar</span>
                    control de limpieza
                </div>

                <v-form
                    :readonly="loading"
                    ref="form"
                    autocomplete="off">
                <!-- FORMULARIO CONTROL -->
                <v-row>
                    <v-col class="px-7 mt-4 mb-n7" cols="7" sm="4" md="3" lg="3" xl="2">
                        <DateFind label="Fecha" v-model="lim.LimTmp" @change="saveData(false, false)"></DateFind>
                    </v-col>
                </v-row>
                <v-row v-if="!$store.state.esTienda">
                    <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                        <DirFind class="mb-n7" ref="dirFind" v-model="lim.Dir" label="Tienda" @change="saveData(false, false)"></DirFind>
                    </v-col>
                </v-row>
                <v-row v-if="DefaultElemCatId == null || DefaultElemCatId == 0">
                    <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                        <ElemCatFind ref="elemCatFind" v-model="lim.ElemCat" label="Tipo" :hideDetails="true" @change="onChangeElemCat"></ElemCatFind>
                    </v-col>
                </v-row>
                <v-row v-if="!$store.state.esTienda || perIdAux == null || perIdAux == 0">
                    <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                        <PerFind class="mb-n7" ref="limPerFind" v-model="lim.Per" label="Controlado por" :perCatIdAnyLevel="101" @change="saveData(false, false)"></PerFind>
                    </v-col>
                </v-row>
                <v-row v-if="DefaultPerIdLim == null || DefaultPerIdLim == 0">
                    <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                        <PerFind class="mb-n7" ref="limPerLimFind" v-model="lim.PerLim" label="Empresa de limpieza" :perCatIdAnyLevel="104" @change="saveData(false, false)"></PerFind>
                    </v-col>
                </v-row>

                <!-- LIMELEM -->
                <v-row class="mt-2">
                    <v-col class="px-7" cols="12" sm="12" md="10" lg="10" xl="8">
                        <!-- Boton añadir elemento -->
                        <v-btn v-if="limElemDataRows.length == 0"
                            :loading="loading"
                            :disabled="editNewLimElem"
                            @click="onBtnNuevoLimElem"
                            color="primary"
                            small
                            class="mb-2">
                            Añadir
                        </v-btn>

                        <v-data-table
                            :headers="limElemHeaders"
                            :items="limElemDataRows"
                            :options.sync="limElemOptions"
                            :footer-props="{itemsPerPageOptions: [100]}"
                            :server-items-length="limElemTotalDataRows"
                            :loading="limElemLoading"
                            style="overflow: scroll"
                            mobile-breakpoint="0"
                            class="elevation-1 limElemDt"
                            dense>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.LimElemFin="{ item }">
                                <v-simple-checkbox 
                                    v-model="item.LimElemFin" 
                                    @input="saveData(false, false)"
                                    color="primary" 
                                    v-ripple>
                                </v-simple-checkbox>
                            </template>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.Opt="{ item }">
                                <v-btn
                                color="error"
                                class="mr-2 my-1"
                                small
                                @click.stop="onBtnShowLimElemDeleteModal(item)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <!-- NUEVO ELEMENTO FORMULARIO -->
                            <template v-if="editNewLimElem" slot="body.append">
                                <tr class="new-row">
                                    <td>
                                        <ElemFind 
                                            ref="newElemFind" 
                                            v-model="newLimElem.Elem" 
                                            :elemCatId="lim.ElemCat != null ? lim.ElemCat.ElemCatId : null"
                                            label="Elemento" 
                                            :hideDetails="true">
                                        </ElemFind>
                                    </td>
                                    <td class="text-center">
                                        <v-simple-checkbox v-model="newLimElem.LimElemFin" color="primary" v-ripple></v-simple-checkbox>
                                    </td>
                                    <td style="min-width: 250px" align ="right">
                                        <v-btn
                                            :loading="loading"
                                            :disabled="!isValidLimElem(newLimElem.Elem)"
                                            @click="onBtnSaveLimElem"
                                            color="primary"
                                            class="mt-2 mb-4 mr-2"
                                            small>
                                            Aceptar
                                        </v-btn>
                                        
                                        <v-btn
                                            @click="onBtnCancelLimElem"
                                            class="mt-2 mb-4 mr-2"
                                            small>
                                            Cancelar
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="px-7 mt-2" cols="12" sm="12" md="10" lg="10" xl="8">
                        <v-textarea
                            class="mt-0 mb-n7"
                            outlined
                            auto-grow
                            v-model="lim.LimObs"
                            label="Observaciones"
                            @change="saveData(false, false)">
                        </v-textarea>
                    </v-col>
                </v-row>

                <v-row v-once>
                    <v-col class="px-7 mt-2" cols="12">
                        <p>
                            Los servicios se deberán hacer adecuadamente en tiempo y CALIDAD, de tal forma que si el acabado no es el adecuado
                            se les pedirá a los operarios de {{nombreProveedorLimpieza}} que lo repasen hasta dejarlo bien. Si no lo hacen bien o se niegan NO
                            firmaréis el parte de trabajo que os entregan y anotaréis que no estáis conformes con el servicio y en observaciones
                            especificáis que no habéis firmado el parte y porque.
                        </p>
                        <p>
                            <strong>LIMPIEZA MENSUAL/BIMENSUAL</strong>: fachada, focos, rótulo, banderola, letras, persiana, toldo, cristales y 
                            aluminios por ambas caras, puerta de entrada y cristales de obrador.
                        </p>
                        <p>
                            <strong>LIMPIEZA CUATRIMESTRAL</strong>: con paño humedecido focos, luces, difusores de aire acondicionado, casettes, 
                            altavoces, rejillas y desempolvado de fluorescentes de tienda.
                        </p>
                    </v-col>
                </v-row>
                  
                <!-- ACEPTAR / SALIR / BORRAR -->
                <v-row class="mt-4" justify="center">
                    <v-btn
                        class="mt-2 mb-4 mr-2"
                        style="width:100px"
                        color="primary"
                        :loading="loading"
                        @click="saveData(true, true)">
                        Aceptar
                    </v-btn>
                    
                    <v-btn
                        class="mt-2 mb-4 mr-2"
                        style="width:100px"
                        @click="goToList">
                        Salir
                    </v-btn>

                    <v-btn v-if="lim.LimId != 0"
                        color="error"
                        class="mt-2 mb-4"
                        style="width:100px"
                        @click="onBtnShowDeleteModal()">
                        Borrar
                    </v-btn>
                </v-row>
                </v-form>
            </v-card>
        </v-container>

        <!-- Modal de borrado -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR CONTROL DE LIMPIEZA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar el control de limpieza? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        color="primary"
                        @click="onBtnDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Borrar detalle -->
        <v-dialog
            v-model="showLimElemDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR ELEMENTO
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar el elemento?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        color="primary"
                        @click="onBtnLimElemDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelLimElemDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import DateFind from "../components/DateFind.vue";
import DirFind from "../components/DirFind.vue";
import ElemFind from "../components/ElemFind.vue";
import ElemCatFind from "../components/ElemCatFind.vue";
import PerFind from "../components/PerFind.vue";
export default ({
    components: { DateFind, DirFind, ElemFind, ElemCatFind, PerFind },
    computed: {
        ...mapState(['empId', 'perId', 'perIdAux', 'nombreUsuarioAux', 'urlRaiz', 'DefaultElemCatId', 'DefaultPerIdLim', 'LoadLimElems']),
        isMartin: function () {
          return (this.empId == 8 || this.empId == 12 || this.empId == 15);
        },
        nombreProveedorLimpieza: function () {
          return this.isMartin ? 
            'Barcino' : // LIMPIEZAS BARCINO 
            'Aguaviva'; // LIMPIEZAS AGUAVIVA
        }
    },
    data () {
        return {
            loading: false,
            isNew: true,
            isValid: false,
            showDeleteDialog: false,
            showLimElemDeleteDialog: false,
            lim: {
                LimId: 0,
                Dir: {},
                DirId: null,
                LimTmp: null,
                ElemCat: {},
                ElemCatId: null,
                Per: {},
                PerId: null,
                PerLim: {},
                PerIdLim: null,
                LimObs: ''
            },
            // Datatable detalle
            limElemLoading: false,
            limElemTotalDataRows: 0,
            limElemDataRows: [],
            limElemOptions: {itemsPerPage: 100},
            limElemHeaders: [
                { text: 'Nombre', width:214, sortable: false, value: 'Elem.ElemNom' },
                { text: 'Conforme', width:86, sortable: false, value: 'LimElemFin', align: 'center' },
                { text: '', width:90, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Edición detalle
            editNewLimElem: false, // Indica si se esta editando el nuevo detalle
            newLimElem: {}, // Datos del nuevo detalle
            selectedLimElem: null // Detalle seleccionado para borrar
        }
    },
    // watch: {
    //   lim: {
    //     handler () {
    //         this.validate();
    //     },
    //     deep: true,
    //   }
    // },
    mounted() {
      this.isNew = this.$route.meta.isNew;

      if (this.isNew) { this.editNewLimElem = true; }
      
      if (this.LoadLimElems == null) this.LoadLimElems = false;

      // Dir Init.
      const dir = this.$store.state.dir;
      if(dir != null && dir.DirId != null && dir.DirId != 0) {
        this.lim.DirId = dir.DirId;
        this.lim.Dir = {
          DirId: dir.DirId,
          DirNom: dir.DirNom
        }
      }
      
      if(!this.isNew) {
        this.lim.LimId = this.$route.params.id;
        this.loadData();
      } else {
        // Tipo init.
        const tipo = this.DefaultElemCatId;
        if(tipo != null && tipo != 0) {
          this.lim.ElemCatId = tipo;
          this.loadDefaultElemCat(this.lim.ElemCatId);
        }

        // Fecha init.
        const today = new Date();
        const todayString = today.getFullYear() + '-' + 
            String(today.getMonth() + 1).padStart(2, '0') + '-' +
            String(today.getDate()).padStart(2, '0');
        this.lim.LimTmp = todayString;

        // Per init.
        if(this.perIdAux != null && this.perIdAux != 0) {
          this.lim.PerId = this.perIdAux;
          this.lim.Per = {
              PerId: this.perIdAux,
              PerNom: this.nombreUsuarioAux
          }
        }

        // Empresa limpieza init
        const defaultPerIdLim = this.DefaultPerIdLim;
        if(defaultPerIdLim != null && defaultPerIdLim != 0) {
          this.loadDefaultPerLim(defaultPerIdLim);
        }
      }
    },
    methods: {
      goToList() {
        this.$router.push('/control-limpieza');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_LIM',
            LimId: this.lim.LimId,
            DirId: this.lim.DirId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/lim", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Lim;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar el control de limpieza.');
                this.goToList();
            }
          });
        })
      },
      getDefaultPerLimFromApi(perIdLim) {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DEFAULT_PERLIM',
            PerId: perIdLim
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/lim", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Per;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar la empresa de limpieza.');
            }
          });
        })
      },
      getDefaultElemCatFromApi(elemCatId) {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_ELEMCAT_WITH_ELEMS',
            EmpId: this.empId,
            ElemCatId: elemCatId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/elemcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.ElemCat;
            const elemList = result.data.Elem;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item,
                    elemList
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar el tipo.');
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Asignamos los IDs de los objetos hijos
        const limElems = this.limElemDataRows.map(m => {
          return {
            ElemId: m.ElemId,
            LimElemFin: m.LimElemFin
          };
        });

        this.lim.DirId = this.lim.Dir != null ? this.lim.Dir.DirId : null;
        this.lim.PerId = this.lim.Per != null ? this.lim.Per.PerId : null;
        this.lim.ElemCatId = this.lim.ElemCat != null ? this.lim.ElemCat.ElemCatId : null;
        this.lim.PerIdLim = this.lim.PerLim != null ? this.lim.PerLim.PerId : null;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            Lim: this.lim,
            LimElems: limElems
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/lim", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar el control de limpieza.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            LimId: this.lim.LimId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/lim", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar el control de limpieza.');
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Control de limpieza no encontrado.');
              this.goToList();
              return;  
            }

            const date = new Date(data.item.LimTmp);
            const dateString = date.getFullYear() + '-' + 
            String(date.getMonth() + 1).padStart(2, '0') + '-' +
            String(date.getDate()).padStart(2, '0');
            data.item.LimTmp = dateString;

            this.lim = data.item;

            this.limElemDataRows = data.item.LimElem.map(m => {
              return {
                ElemId: m.Elem.ElemId,
                Elem: {
                  ElemNom: m.Elem.ElemNom
                },
                LimElemFin: m.LimElemFin
              }
            });
            this.limElemTotalDataRows = data.item.LimElem.length;
          });
      },
      loadDefaultPerLim(perIdLim) {
        this.getDefaultPerLimFromApi(perIdLim)
          .then(data => {
            if(data.item == null) {
              alert('Empresa de limpieza no encontrada.');
              return;  
            }

            this.lim.PerLim = data.item;
            this.lim.PerIdLim = data.item.PerId;
          });
      },
      loadDefaultElemCat(elemCatId) {
        this.getDefaultElemCatFromApi(elemCatId)
          .then(data => {
            if(data.item == null) {
              alert('Tipo de limpieza no encontrado.');
              return;  
            }

            this.lim.ElemCat = data.item;
            if(this.LoadLimElems) {
              this.limElemDataRows = data.elemList.map(m => {
                return {
                  ElemId: m.ElemId,
                  Elem: {
                    ElemNom: m.ElemNom
                  },
                  LimElemFin: false
                }
              });
              this.limElemTotalDataRows = data.elemList.length;
            }
          });
      },
      //#endregion

      //#region  Añadir detalle
      onBtnNuevoLimElem() {
        this.editNewLimElem = true;
        this.newLimElem = {
          Elem: null,
          LimElemFin: false
        };
      },
      //#endregion

      //#region Guardado
      saveData(goToList = false, checkValidation = false) {
          if(this.loading) { return; }
          this.validate(checkValidation);
          if(!this.isValid) { return; }
          this.postDataToApi().then(data => {
            if(data.item.LimId != null) {
                if(this.lim.LimId == 0) {
                  this.lim.LimId = data.item.LimId;
                }
                if(goToList) { this.goToList(); }
            }
            else {
              alert(data.item.Message);
            }
          });
      },
      onBtnSaveLimElem() {
        if(!this.isValidLimElem(this.newLimElem.Elem)) { return; }
        
        const newLimElem = {
          ElemId: this.newLimElem.Elem.ElemId,
          Elem: {
            ElemNom: this.newLimElem.Elem.ElemNom
          },
          LimElemFin: this.newLimElem.LimElemFin
        };

        this.limElemDataRows.push(newLimElem);
        this.limElemTotalDataRows += 1;

        this.newLimElem = {};
        this.editNewLimElem = false;

        this.saveData();
      },
      onChangeElemCat() {
        this.limElemDataRows = [];
        this.limElemTotalDataRows = 0;
        
        if(this.lim.ElemCat == null) { return; }
        this.getDefaultElemCatFromApi(this.lim.ElemCat.ElemCatId)
          .then(data => {
            if(data.item == null) {
              alert('Tipo de limpieza no encontrado.');
              return;
            }

            if(this.LoadLimElems) {
              this.limElemDataRows = data.elemList.map(m => {
                return {
                  ElemId: m.ElemId,
                  Elem: {
                    ElemNom: m.ElemNom
                  },
                  LimElemFin: false
                }
              });
              this.limElemTotalDataRows = data.elemList.length;
            }

            this.saveData();
          });
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region  Cancelar creación de detalle
      onBtnCancelLimElem() {
        this.editNewLimElem = false;
        this.newLimElem = {};
      },
      //#endregion

      //#region  Eliminar detalle
      removeLimElem(item) {
        var index = this.limElemDataRows.indexOf(item);
        if (index != -1) {
          this.limElemDataRows.splice(index, 1);
          this.limElemTotalDataRows -= 1;
        }
      },
      onBtnLimElemDelete() {
        this.removeLimElem(this.selectedLimElem);
        this.selectedLimElem = null;
        this.showLimElemDeleteDialog = false;
        this.saveData();
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowLimElemDeleteModal(item) {
        this.selectedLimElem = item;
        this.showLimElemDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelLimElemDelete() {
        this.selectedLimElem = null;
        this.showLimElemDeleteDialog = false;
      },
      //#endregion
      

      //#region Validar formulario
      validate(checkValidation = false) {
          let isValid = true;
          let msg = '';

          if (this.limElemDataRows.length == 0) { 
            msg = 'No puedes guardar un control de limpieza sin ninguna línea en el detalle.';
            isValid = false; 
          }
          else if (this.lim.LimTmp == null) { 
            msg = 'El campo Fecha es obligatorio.';
            isValid = false; 
          }
          else if (this.lim.Dir == null || this.lim.Dir.DirId == null) { 
            msg = 'El campo Tienda es obligatorio.';
            isValid = false; 
          }
          else if (this.lim.ElemCat == null || this.lim.ElemCat.ElemCatId == null) { 
            msg = 'El campo Tipo es obligatorio.';
            isValid = false; 
          }
          else if (this.lim.Per == null || this.lim.Per.PerId == null) { 
            msg = 'El campo Controlado por es obligatorio.';
            isValid = false; 
          }
          else if (this.limElemDataRows.some(s => !s.LimElemFin) && (this.lim.LimObs == null || this.lim.LimObs === '')) {
            msg = 'Debes especificar un motivo para las limpiezas no conformes.';
            isValid = false;
          }

          if (!isValid && checkValidation) { alert(msg); }
          
          this.isValid = isValid;
          return isValid;
      },
      isValidLimElem(elem) {
        if (elem == null || elem.ElemId == null) { return false; }
        if (this.limElemDataRows.map(m => m.ElemId).indexOf(elem.ElemId) >= 0) { return false; }
        return true;
      }
      //#endregion
    } 
})
</script>
